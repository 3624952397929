<template>
  <div class="information">
    <div>人脉圈成员</div>
    <div class="content">
      <div class="contentTitle">全部成员</div>
      <div @click="addContent" class="addContent">新增成员</div>
    </div>
    <a-spin :spinning="spinning">
      <div class="contactClass" v-for="item in contactList" :key="item.id">
        <div class="contactClassLeft">
          <a-avatar
            v-if="item.avatar"
            :src="setAvatar(item.avatar)"
            @click="handleGoToContact(item)"
            class="headerUrl"
          />
          <div v-else @click="handleGoToContact(item)" class="headerText">
            <div v-if="item.name">
              {{ item.name[0] }}
            </div>
          </div>
          <div class="contactsName">{{ item.name }}</div>
        </div>
        <div class="groupDel" @click="handleDel(item)">
          <img src="@/assets/images/box/delete.png" alt="" class="delImg" />
        </div>
      </div>
    </a-spin>

    <add-contacts-circle
      v-model:visible="visible"
      :init="activeItem"
      @fnOk="handleContacts"
    />
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue';
import { message, Spin, Avatar } from 'ant-design-vue';
import addContactsCircle from '@/views/contactsCircle/addContactsCircle';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { setAvatar } from '@/utils';
import contactsCircleApi from '@/api/contactsCircle';
// import contactAll from "@/api/contactAll";

export default {
  components: {
    addContactsCircle,
    ASpin: Spin,
    AAvatar: Avatar,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const { id } = route.params;

    const state = reactive({
      userId: store.state.account.user.id,
      visible: false,
      activeItem: {},
      contactList: [],
      spinning: false,
    });

    // 右侧成员信息查询
    const getContactList = () => {
      contactsCircleApi
        .queryConnectionContacts('', {
          connectionsId: id,
          userId: state.userId,
        })
        .then(res => {
          if (res) {
            state.contactList = res;
            setTimeout(() => {
              state.spinning = false;
            }, 1000);
          }
        });
    };

    onMounted(() => {
      getContactList();
    });

    return {
      ...toRefs(state),
      getContactList,
      setAvatar,
      handleDel(item) {
        let parmas = {
          userId: state.userId,
          connectionsId: id,
          contactId: item.id,
        };
        contactsCircleApi.deleteConnectionsUser('', parmas).then(() => {
          message.success('删除成功');
          state.spinning = true;
          // 查询脉圈下全部成员
          getContactList();
        });
      },
      addContent() {
        state.visible = true;
        state.activeItem = id;
      },
      handleContacts(value) {
        console.log('value', value)
        state.visible = value.visible;
        state.spinning = !value;
        // 查询脉圈下全部成员
        getContactList();
      },
      handleGoToContact(item) {
        router.push(`/contacts/${item.id}`);
      },
    };
  },
};
</script>

<style scoped lang="less">
.information {
  margin: 16px 20px;
  max-height: 662px;
  overflow: auto;
  padding-right: 8px;
  padding-left: 2px;
}
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  .contentTitle {
  }

  .addContent {
    color: #ff9634;
    cursor: pointer;
  }
}
.contactClass {
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  .contactClassLeft {
    border: 0.5px solid #eeeeee;
    border-right: none;
    width: 89%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 8px;
    border-radius: 4px;

    .contactsName {
    }
  }
  .groupDel {
    border: 0.5px solid #eeeeee;
    width: 11%;
    padding: 6px 8px;
    text-align: center;
    border-radius: 0px 4px 4px 0px;
    cursor: pointer;
  }
}

.groupCalss {
  background-color: #f5f6fa;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 8px 6px;
  margin-top: 10px;
  .groupContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    //border: 1px solid #F2F3F7;
    //background: #FFFFFF;
    //padding: 8px 12px;
    border-radius: 4px;
    margin-right: 8px;
    margin-top: 8px;

    .groupDel {
      cursor: pointer;
      border: 1px solid #f2f3f7;
      background: #ffffff;
      padding: 12px;
      border-radius: 4px;
    }
    .name {
      border: 1px solid #f2f3f7;
      background: #ffffff;
      padding: 5px 12px;
      border-radius: 4px;
    }
  }
}
.headerUrl {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 12px;
}
.headerText {
  margin-right: 12px;
}
</style>
