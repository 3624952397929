<template>
  <div>
    <a-row>
      <a-col :span="17" class="iss-main">
        <a-row class="left">
          <a-col :span="19">
            <div class="avatarMessage">
              <div class="avatarleft">
                <div class="headImg">
                  <component
                    class="marginBottom5 iconClass"
                    :is="icons[`${form.icon || 'UserOutlined'}`]"
                  ></component>
                  <!--                  <div class="mask">-->
                  <!--                    &lt;!&ndash;                        <PlusOutlined class="mask-icon" />&ndash;&gt;-->
                  <!--                    <iss-image-upload-->
                  <!--                      list-type="picture-card"-->
                  <!--                      accept=".jpe,.jpeg,.jpg,.png"-->
                  <!--                      v-model:value="form.icon"-->
                  <!--                      class="imageUpload mask-icon"-->
                  <!--                    />-->
                  <!--                  </div>-->
                </div>
              </div>

              <div class="avatarRight">
                <!-- layout="vertical" -->
                <a-form>
                  <a-form-item label="" class="nameClass">
                    <a-input
                      v-model:value="form.connectionsName"
                      placeholder="请输入脉圈名称"
                      style="width: 55%"
                      @blur="handleBlur('connectionsName')"
                    />
                  </a-form-item>

                  <a-form-item label="" class="positionClass">
                    <a-textarea
                      v-model:value="form.description"
                      placeholder="请输入描述"
                      style="width: 55%"
                      @blur="handleBlur('description')"
                    />
                  </a-form-item>
                </a-form>
              </div>
            </div>
          </a-col>
          <a-col :span="5">
            <!-- <a-select placeholder="请选择" v-model:value="form.value">
              <a-select-option
                v-for="ol in operatorList"
                :key="ol.value"
                :value="ol.value"
              >
                {{ ol.label }}
              </a-select-option>
            </a-select> -->
            <a-dropdown>
              <template #overlay>
                <a-menu>
                  <a-menu-item key="1" @click="deleteCircle">
                    <DeleteOutlined />
                    删除人脉圈
                  </a-menu-item>
                </a-menu>
              </template>
              <a-button>
                操作
                <DownOutlined />
              </a-button>
            </a-dropdown>
          </a-col>
        </a-row>

        <!--          tab  -->
        <div class="tabsClass">
          <a-tabs v-model:activeKey="activeKey">
            <a-tab-pane key="1" tab="待办">
              <circle-reminder @timeLineRefersh="timeLineRefersh" />
            </a-tab-pane>
            <a-tab-pane key="2" tab="时刻" force-render>
              <TimeLine ref="timeline" :circleId="id"></TimeLine>
            </a-tab-pane>
          </a-tabs>
          <div
            class="addReminder"
            v-if="activeKey == 1"
            @click="handleAddReminder"
          >
            新增待办
          </div>
          <div class="addReminder" v-else @click="addModalShow">新增记录</div>
        </div>
      </a-col>

      <a-col :span="6" class="iss-main iss-main-mr">
        <div class="tabsRight">
          <!--          <a-tabs v-model:activeKey="activeKeyRight">-->
          <!--            <a-tab-pane key="1" tab="基本信息">-->
          <!--              <iss-information />-->
          <!--            </a-tab-pane>-->
          <!--            <a-tab-pane key="2" tab="详细信息" force-render>-->
          <!--              <iss-details />-->
          <!--            </a-tab-pane>-->
          <!--            <a-tab-pane key="3" tab="相关联系人" force-render>-->
          <!--              <iss-contacts />-->
          <!--            </a-tab-pane>-->
          <!--          </a-tabs>-->

          <iss-contacts />
        </div>
      </a-col>
    </a-row>
  </div>
  <cr-edit-page
    v-model:visible="editPage"
    :init="activeItem"
    @fnOk="handleEditPage"
  />
  <AddModal
    :circleId="id"
    :visiable="addShow"
    @closeModal="addModalClose"
  ></AddModal>
</template>

<script>
import {
  Avatar,
  Row,
  Col,
  Select,
  Tabs,
  Form,
  message,
  dropdown,
  menu,
  Modal,
  // DatePicker,
} from 'ant-design-vue';
import { onMounted, reactive, ref, toRefs, watch, createVNode } from 'vue';
import { local } from '@/utils/storage';
import IssContacts from '@/views/contactsCircle/detailsPage/contacts';
import contactsCircleApi from '@/api/contactsCircle';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import * as icons from '@ant-design/icons-vue';
import circleReminder from '@/views/contactsCircle/detailsPage/circleReminder';
import crEditPage from '@/views/contactsCircle/detailsPage/components/crEditPage';
import TimeLine from '@/views/timeline/components/timeline.vue';
import AddModal from '@/views/timeline/components/addModal.vue';

// import { UserOutlined } from '@ant-design/icons-vue';
// import IssImageUpload from '@/components/imageUpload';

export default {
  components: {
    // ADrawer: Drawer,
    ARow: Row,
    ACol: Col,
    // UserOutlined,
    AAvatar: Avatar,
    ASelect: Select,
    ASelectOption: Select.Option,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    circleReminder,
    crEditPage,
    // IssImageUpload,
    IssContacts,
    AForm: Form,
    AFormItem: Form.Item,
    Icon: icons,
    TimeLine,
    AddModal,
    // ADatePicker: DatePicker,
    DeleteOutlined: icons.DeleteOutlined,
    DownOutlined: icons.DownOutlined,
    ADropdown: dropdown,
    AMenu: menu,
    AMenuItem: menu.Item,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    const mainRef = ref();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { id } = route.params;

    const state = reactive({
      visible: false,
      activeKey: '1',
      editPage: false,
      activeItem: {},
      // activeKeyRight: '1',
    });
    const form = reactive({
      userId: store.state.account.user.id,
      icon: '',
      value: '操作',
      connectionsName: '',
      description: '',
    });

    // 左侧信息查询
    const getTitleName = () => {
      console.log('id劳动力大量', id);
      contactsCircleApi.getTitleName('', id).then(res => {
        if (res) {
          Object.assign(form, res);
          // 把数据存储一份到local
          local.set('contactsCirclelist', res);
        }
      });
    };

    const addShow = ref(false);
    const timeline = ref();

    const addModalShow = e => {
      addShow.value = true;
    };

    const addModalClose = e => {
      addShow.value = false;
      if (e) {
        // infoData()
        console.log('数据刷新');
        timeline.value.initData();
      }
    };

    const timeLineRefersh = () => {
      console.log('数据刷新');
      timeline.value.initData();
    };

    const deleteCircle = () => {
      Modal.confirm({
        title: `您确定要删除此人脉圈吗？与该人脉圈相关的待办，将会转为联系人待办。`,
        icon: createVNode(icons.ExclamationCircleOutlined),
        closable: false,
        okText: '确定',
        cancelButtonProps: null,
        onOk() {
          contactsCircleApi
            .deleteItem('', {
              userId: store.state.account.user.id,
              connectionsIds: [id],
            })
            .then(res => {
              console.log(res);
              if (res) {
                router.back();
              }
            });
        },
      });
    };

    onMounted(() => {
      getTitleName();
    });

    watch(
      () => props.visible,
      value => {
        state.visible = value;
      }
    );

    return {
      id,
      mainRef,
      form,
      icons,
      getTitleName,
      ...toRefs(state),
      addShow,
      timeline,
      addModalShow,
      addModalClose,
      timeLineRefersh,
      deleteCircle,
      handleClose() {
        context.emit('fnOk', false);
      },
      // 人脉圈新增待办 todo
      handleAddReminder() {
        let parmas = {
          connectionsId: route.params.id,
          comeFrom: 'crDetail',
          type: 'add',
        };
        state.editPage = true;
        Object.assign(state.activeItem, parmas);
      },
      handleEditPage(value) {
        state.editPage = value;
      },
      handleBlur(type) {
        const obj = JSON.parse(JSON.stringify(form));
        let data = local.get('contactsCirclelist');

        for (let key in data) {
          if (key === type) {
            if (data[key] !== obj[key]) {
              // 接口
              contactsCircleApi
                .connections('', {
                  ...obj,
                })
                .then(res => {
                  if (res) {
                    message.success('操作成功');
                    getTitleName();
                  }
                });
            } else {
              continue;
            }
          }
        }
      },
    };
  },
};
</script>

<style scoped lang="less">
.iss-main {
  height: calc(100vh - 90px);
  overflow: auto;
  padding: 24px 16px 16px 16px;
}
.iss-main-mr {
  padding: 0;
  margin: 16px 0px 0px 0px;
}
.tabsRight /deep/ .ant-tabs-nav .ant-tabs-tab {
  margin: 0px;
  padding: 12px 14px;
}
.left {
  font-size: 12px;
}
.avatarMessage {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
}
.avatarRight {
  color: #6b7592;
  margin-left: 24px;
  width: 100%;
}
//.nameClass {
//  color: #3F4557;
//  font-size: 20px;
//}
.nameClass /deep/ .ant-input {
  color: #3f4557;
  font-size: 20px;
  //margin-bottom: 8px;
  padding: 0px;
}
.positionClass /deep/ .ant-input {
  color: #3f4557;
  padding: 0px;
}
//.interaction {
//  margin: 16px 0px;
//}

//.three {
//  align-items: center;
//  display: flex;
//  align-content: end;
//  flex-wrap: wrap;
//  //justify-content: center;
//  .date {
//    color: #3F4557;
//  }
//}

.two {
  //margin: 0 auto;
  text-align: center;
  align-items: center;
  display: flex;
  align-content: flex-end;
  flex-wrap: wrap;
  .weekend {
    color: #3f4557;
    //margin-top: 16px;
    //margin-right: 30px;
  }
}

.tabsClass {
  position: relative;
  margin-top: 38px;
}
.imageUpload {
  display: none;
}
.avatarleft:hover {
  display: inline-block;
}

.headImg {
  //position: absolute;
  right: 10px;
  cursor: pointer;
}

//.mask {
//  opacity: 0;
//  //position: absolute;
//  background: rgba(0, 0, 0, 0.4);
//  cursor: pointer;
//  transition: opacity 0.4s ease 0s;
//  .mask-icon {
//    font-size: 2rem;
//    position: absolute;
//    //top: 28px;
//    //left: 48px;
//    top: 18px;
//    left: 38px;
//    margin-left: -1rem;
//    margin-top: -1rem;
//    color: rgb(214, 214, 214);
//  }
//  &:hover {
//    opacity: 1;
//  }
//}
//.mask
//  /deep/
//  .iss-upload.ant-upload-picture-card-wrapper[data-v-5a6f0948]
//  .ant-upload.ant-upload-select-picture-card {
//  border-radius: 50px;
//  //border: none;
//}

.ant-input {
  background-color: #ffffff;
  border: 1px solid #ffffff;
}
.avatarRight
  /deep/
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #ffffff;
  border: 1px solid #ffffff;
}
.avatarRight /deep/ .ant-select {
  color: #97a5ce;
}
//.interaction /deep/ .ant-col-16 {
//  max-width: 100%;
//}
.ant-form-item {
  margin-bottom: 0px;
}
.interaction
  /deep/
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: none;
}
.iconClass {
  font-size: 48px;
  color: #ff7b00;
}
.ant-row.ant-form-item.nameClass {
  margin-bottom: 5px;
}
.addReminder {
  padding: 8px 12px;
  background: #ff7b00;
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
  color: #ffffff;
  text-align: center;
  cursor: pointer;

  position: absolute;
  top: 0px;
  right: 0px;
}
.tabsClass /deep/ .ant-tabs-bar {
  margin: 4px 0px 0px 0px;
  border-bottom: 0.5px solid #eeeeee;
}
</style>
